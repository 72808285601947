/* eslint-disable @typescript-eslint/naming-convention */
import { OrderScenario } from 'components/order/model/Order';
import {
    isArrayOf,
    isBoolean,
    isDefined,
    isNumber,
    isOptional,
    isOptionalArrayOf,
    isOptionalString,
    isString
} from 'lib/typeInference';

export enum DaysOfTheWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export const DAYS_OF_THE_WEEK: DaysOfTheWeek[] = [
    DaysOfTheWeek.MONDAY,
    DaysOfTheWeek.TUESDAY,
    DaysOfTheWeek.WEDNESDAY,
    DaysOfTheWeek.THURSDAY,
    DaysOfTheWeek.FRIDAY,
    DaysOfTheWeek.SATURDAY,
    DaysOfTheWeek.SUNDAY
];

export enum LocationButtonActions {
    PREORDER = 'PREORDER',
    CHECKIN = 'CHECKIN',
    BROWSER = 'BROWSER',
    OPENING_TIMES = 'OPENING_TIMES',
    OPEN_TAB = 'OPEN_TAB',
    TABLE_ORDER = 'TABLE_ORDER',
    EVENTS = 'EVENTS'
}

export function isLocationButtonActions(action: any): action is LocationButtonActions {
    return isString(action) && action in LocationButtonActions;
}

export enum LocationButtonStyle {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY'
}

export function isLocationButtonsStyle(style: any): style is LocationButtonStyle {
    return isString(style) && style in LocationButtonStyle;
}

export interface IButtonProperties {
    properties: {
        url: string;
    };
}

export function isButtonProperties(properties: any): properties is IButtonProperties {
    return isDefined(properties) && isString(properties.url);
}

export interface IButton {
    title: string;
    style: LocationButtonStyle;
    action: LocationButtonActions;
    properties?: IButtonProperties;
}

export function isButton(button: any): button is IButton {
    return (
        isDefined(button) &&
        isString(button.title) &&
        isLocationButtonsStyle(button.style) &&
        isLocationButtonActions(button.action) &&
        isOptional(isButtonProperties, button.properties)
    );
}

export enum LocationState {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    RETIRED = 'RETIRED'
}

export function isLocationState(state: any): state is string {
    return isString(state) && state in LocationState;
}

export interface IServices {
    checkins?: boolean;
    offline_checkin?: boolean;
    pre_order: boolean;
    book_table?: boolean;
    book_room?: boolean;
    tab?: boolean;
    order_to_table?: boolean;
    order_to_table_nfc?: boolean;
    pay_at_table: boolean;
    pay_at_table_nfc: boolean;
}

export type LocationService = keyof IServices;

export function isServices(services: any): services is IServices {
    return (
        isOptional(isBoolean, services.checkins) &&
        isOptional(isBoolean, services.offline_checkin) &&
        isBoolean(services.pre_order) &&
        isOptional(isBoolean, services.book_table) &&
        isOptional(isBoolean, services.book_room) &&
        isOptional(isBoolean, services.tab) &&
        isOptional(isBoolean, services.order_to_table) &&
        isOptional(isBoolean, services.order_to_table_nfc)
    );
}

export interface ILink {
    title?: string;
    parameters?: string;
    enabled?: boolean;
}

export interface ILinks {
    facebook?: ILink;
    twitter?: ILink;
    foursquare?: ILink;
    instagram?: ILink;
    uber?: ILink;
    hailo?: ILink;
    citymapper?: ILink;
}

export function isLink(link: any): link is ILink {
    return (
        isOptionalString(link.title) &&
        isOptionalString(link.parameters) &&
        isOptional(isBoolean, link.enabled)
    );
}

export function isLinks(links: any): links is ILinks {
    return (
        isDefined(links) &&
        isOptional(isLink, links.facebook) &&
        isOptional(isLink, links.twitter) &&
        isOptional(isLink, links.foursquare) &&
        isOptional(isLink, links.instagram) &&
        isOptional(isLink, links.uber) &&
        isOptional(isLink, links.hailo) &&
        isOptional(isLink, links.citymapper)
    );
}

export interface OrderNote {
    description: string;
    title: string;
    channels?: {
        TERMINAL?: boolean;
        APP?: boolean;
        WEB?: boolean;
    };
}

export interface Location {
    _id: string;
    address: LocationAddress;
    alternativeOpeningHoursText?: string;
    beacons: any[];
    buttons: LocationButton[];
    contacts: LocationContacts;
    created: string;
    extended?: LocationExtended;
    facilities: string[];
    geo: number[];
    description: string;
    distance?: number;
    links: ILinks;
    listingImageUrl?: string;
    mainImageUrl?: string;
    openingHours: IOpeningHours;
    regions?: any[];
    services: IServices;
    state: LocationState;
    tags: string[];
    tenantId: string;
    title: string;
    VATnumber: string;
    updated: string;
    timezone: string;
    ordering?: {
        extendedFields: Record<string, { note: OrderNote[] }>;
    };
    zones?: Record<string, number[][]>;
}

export interface LocationAddress {
    address: string;
    country: string;
    fullAddress: string;
    googleMapsShortUrl: string;
    postcode: string;
    summary: string;
    town: string;
}

export interface LocationContacts {
    email: string;
    phone: string;
}

export interface LocationButton {
    title: string;
    style: 'PRIMARY' | 'SECONDARY';
    action: LocationButtonActions;
    properties: {
        url: string;
    };
}

export interface IOpeningHours {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
}

export function isOpeningHours(openingHours: any): openingHours is IOpeningHours {
    return (
        isString(openingHours.monday) &&
        isString(openingHours.tuesday) &&
        isString(openingHours.wednesday) &&
        isString(openingHours.thursday) &&
        isString(openingHours.friday) &&
        isString(openingHours.saturday) &&
        isString(openingHours.saturday)
    );
}

export interface LocationExtended {
    bookRoomId: string;
    bookTableId: string;
    secretDjId: string;
}

export type LocationsSummary = { total: number; count: number; pages: number; page: number } | null;

function isLocationsSummary(summary: any): summary is LocationsSummary {
    return (
        !summary ||
        (isNumber(summary.total) &&
            isNumber(summary.count) &&
            isNumber(summary.pages) &&
            isNumber(summary.page))
    );
}

export function isLocation(location: any): location is Location {
    return (
        isDefined(location) &&
        isString(location.title) &&
        isOptional(isLocationState, location.state) &&
        isOptionalString(location.mainImageUrl) &&
        isOptionalString(location.listingImageUrl) &&
        isOptional(isOpeningHours, location.openingHours) &&
        isOptionalString(location.alternativeOpeningHoursText) &&
        isOptionalArrayOf(isString, location.facilities) &&
        isOptional(isServices, location.services) &&
        isOptionalArrayOf(isButton, location.buttons) &&
        isOptional(isLinks, location.links) &&
        isOptional(isDefined, location.extended) &&
        isOptionalString(location.description) &&
        isOptional(isDefined, location.address) &&
        isOptional(isDefined, location.contacts) &&
        isOptional(isDefined, location.beacons) &&
        isOptionalString(location.VATnumber) &&
        isArrayOf(isNumber, location.geo) &&
        isOptionalArrayOf(isString, location.regions) &&
        isOptionalArrayOf(isString, location.tags)
    );
}

export function isLocations(locations: any[]): locations is Location[] {
    return !locations || !locations.length || isLocation(locations[0]);
}

interface ISummary {
    total: number;
    count: number;
    pages: number;
    page: number;
}

export interface LocationsData {
    locations: Location[];
    summary: ISummary;
}

export function isLocationsData(data: any): data is LocationsData {
    return !data || (isLocations(data.locations) && isLocationsSummary(data.summary));
}

export function getOrderNotes(location: Location | undefined, scenario: OrderScenario | undefined) {
    let _notes: OrderNote[] = [];
    if (!location || !scenario) {
        return _notes;
    }
    if (OrderScenario.PREORDER === scenario) {
        _notes = location.ordering?.extendedFields.PREORDER?.note ?? [];
    }
    if (OrderScenario.ORDER_TO_TABLE === scenario) {
        _notes = location.ordering?.extendedFields.ORDER_TO_TABLE?.note ?? [];
    }
    return _notes.filter(item => !isBoolean(item?.channels?.WEB) || item?.channels?.WEB);
}
